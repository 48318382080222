import React, { useEffect, useState } from "react";
import { Slider, Col, Typography, notification, InputNumber } from "antd";
import PageComponent from "../../../_components/page/PageComponent";
import REQUESTS from "../../../api/requests";
import "antd/dist/reset.css";
import ButtonComponent from "../../../_components/ButtonComponent";

const { Text } = Typography;

const AppRadiusesPage = () => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRadiusData = () => {
    try {
      REQUESTS.APP_RADIUS.GET()
        .then((res) => {
          console.log(res);
          const data = res?.message;

          const newDataList = [];

          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              if (key !== "id" && key !== "createdAt" && key !== "updatedAt") {
                newDataList.push({
                  key: key,
                  label: key.replace(/_/g, " "),
                  value: parseFloat(data[key]),
                });
              }
            }
          }

          setDataList(newDataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSliderChange = (index, value) => {
    const newDataList = [...dataList];
    newDataList[index].value = parseFloat(value.toFixed(1));
    setDataList(newDataList);
  };

  const handleInputChange = (index, value) => {
    const newDataList = [...dataList];
    newDataList[index].value = parseFloat(value.toFixed(1)) || 0;
    setDataList(newDataList);
  };

  const handleClick = () => {
    setIsLoading(true);

    const body = {};

    dataList.forEach((item) => {
      body[item.key] = item.value?.toString();
    });

    REQUESTS.APP_RADIUS.UPDATE(body)
      .then((res) => {
        setIsLoading(false);

        if (!res.error) {
          notification.success({
            message: res?.message,
          });
        } else {
          notification.error({
            message: res.message,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  useEffect(() => {
    getRadiusData();
  }, []);

  return (
    <PageComponent routes={["Application", "App radiuses"]}>
      <div style={{ maxWidth: "70%" }}>
        {dataList?.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Col span={6}>
              <Text>{item.label}</Text>
            </Col>
            <Col span={12}>
              <Slider
                min={0}
                max={200}
                step={0.1}
                defaultValue={item.value}
                value={typeof item.value === "number" ? item.value : 0}
                onChange={(value) => handleSliderChange(index, value)}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                max={200}
                step={0.1}
                value={item.value}
                onChange={(value) => handleInputChange(index, value)}
              />
            </Col>
            <Col span={8}>
              <Text>{item.value} PX</Text>
            </Col>
          </div>
        ))}
        <div style={{ marginTop: "40px" }}>
          <ButtonComponent
            onClick={handleClick}
            isLoading={isLoading}
            actionType={"save"}
            title={"Save"}
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          />
        </div>
      </div>
    </PageComponent>
  );
};

export default AppRadiusesPage;
