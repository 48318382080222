import { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ImageUpload from "../../../components/ImageUpload";
import ButtonComponent from "../../../_components/ButtonComponent";
import PageComponent from "../../../_components/page/PageComponent";

export default function LauncherConfigsPage({}) {
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [image, setImage] = useState({
    url: null,
    file: null,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    let formData = new FormData();

    formData.append("launcher_build_number", values.launcher_build_number);
    formData.append("launcher_url", values.launcher_url);
    formData.append("launcher_version", values.launcher_version);
    formData.append("min_version_code", values.min_version_code);

    if (image.file) {
      formData.append("launcher_background_image", image.file);
    }

    REQUESTS.LAUNCHER.CONFIGS.EDIT(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });

        getData();
        setIsDisabled(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsDisabled(true);
      });
  };

  const getData = () => {
    setIsDisabled(true);

    try {
      REQUESTS.LAUNCHER.CONFIGS.GET().then((response) => {
        setIsDisabled(false);
        if (!response.error) {
          const {
            launcher_build_number,
            launcher_url,
            launcher_version,
            min_version_code,
            launcher_background_image,
          } = response.message;

          form.setFields([
            { name: "launcher_build_number", value: launcher_build_number },
            { name: "launcher_url", value: launcher_url },
            { name: "launcher_version", value: launcher_version },
            { name: "min_version_code", value: min_version_code },
          ]);

          setImage({
            url: launcher_background_image,
            file: null,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <PageComponent routes={["Launcher", "Configs"]}>
      <Form
        form={form}
        name="launcher-configs"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        disabled={isDisabled}
        style={{ width: 400 }}
      >
        <Form.Item
          label="Background image"
          name="launcher_background_image"
          rules={[
            {
              required: image.url ? false : true,
              message: "Please upload background image",
            },
          ]}
          style={{
            width: 150,
          }}
        >
          <ImageUpload image={image} setImage={setImage} />
        </Form.Item>

        <Form.Item
          label="Url"
          name="launcher_url"
          rules={[
            {
              required: true,
              message: "Please input url",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            label="Version"
            name="launcher_version"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input version",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Version Code"
            name="min_version_code"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input version code",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item
          label="Build number"
          name="launcher_build_number"
          style={{ width: "calc(50% - 20px)" }}
          rules={[
            {
              required: true,
              message: "Please input build number",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </PageComponent>
  );
}
