import { useCallback, useEffect, useState } from "react";
import { Collapse, Divider, notification } from "antd";

import REQUESTS from "../../../api/requests";
import { getToken } from "../../../server/requests";

import VodTranscodingTable from "./VodTranscodingTable";

import confirmDelete from "./components/confirmDelete";
import ServerStorage from "./components/ServerStorage";
import VideoModal from "../../../_components/VideoModal";
import PageComponent from "../../../_components/page/PageComponent";
import VodTranscodingDrawer from "./components/VodTranscodingDrawer";
import ButtonComponent from "../../../_components/ButtonComponent";
import icons from "../../../config/icons";
import CollapseComponent from "./components/collapse/CollapseComponsnt";

export default function VodTranscodingPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [playingVideo, setPlayingVideo] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.VOD.TRANSCODING.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "delete":
        const callback = () => setGetAgain((prev) => !prev);

        confirmDelete(item.id, callback);
        break;

      case "start":
        REQUESTS.VOD.TRANSCODING.START({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      case "stop":
        REQUESTS.VOD.TRANSCODING.STOP({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  const closeVideoModal = useCallback(() => {
    setPlayingVideo(null);
  }, []);

  const handleCsv = (value) => {
    if (value) {
      const formData = new FormData();
      formData.append("csv", value);
      REQUESTS.VOD.TRANSCODING.IMPORT.IMPORT_CSV(formData)
        .then((res) => {
          notification.success({
            success: "success",
            message: "CSV file imported",
          });
        })
        .catch((err) => {
          console.log(err);
          notification.error({
            error: "error",
            message: "something wrong",
          });
        });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setGetAgain((prev) => !prev);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return (
    <PageComponent routes={["Vod", "Transcoding"]}>
      {/* <ServerStorage /> */}
      <CollapseComponent>
        {/* <div>sdsd</div> */}
        <ServerStorage />
      </CollapseComponent>

      <VodTranscodingTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        openPlayingVideo={(item) => setPlayingVideo(item)}
        handle_CSV={(value) => handleCsv(value)}
      />

      <VideoModal
        open={playingVideo ? true : false}
        onClose={closeVideoModal}
        title={playingVideo?.name}
        url={playingVideo?.stream_url}
      />

      <VodTranscodingDrawer
        open={isOpenDrawer}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
      />
    </PageComponent>
  );
}
