import { useState } from "react";

import { Tabs, notification } from "antd";

import REQUESTS from "../../../api/requests";

import LauncherDevicesTable from "./LauncherDevicesTable";
import PageComponent from "../../../_components/page/PageComponent";
import NewLauncherDevice from "./NewLauncherDevice";

export default function LauncherDevicesPage({}) {
  const [getAgain, setGetAgain] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.LAUNCHER.DEVICES.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeBlock = (item) => {
    const query = {
      id: item.id,
      is_blocked: !item.is_blocked,
    };

    REQUESTS.LAUNCHER.DEVICES.BLOCK(query)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const activation = (id) => {
    setActivationLoading(true);
    REQUESTS.LAUNCHER.DEVICES.ACTIVATE({ id })
      .then((res) => {
        console.log(res);
        setActivationLoading(false);

        if (!res.error) {
          notification.success({
            message: res?.message,
          });
          setGetAgain((prev) => !prev);
        } else {
          notification.error({
            message: res?.message,
          });
        }
      })
      .catch((error) => {
        setActivationLoading(false);

        console.log(error);
      });
  };

  const devicePageTabs = [
    {
      label: "All",
      key: "all",
      children: (
        <LauncherDevicesTable
          onChange={onChangeBlock}
          getAgain={getAgain}
          getDataSource={getDataSource}
          activation={activation}
          buttonLoading={activationLoading}
        />
      ),
    },
    {
      label: "New",
      key: "new",
      children: (
        <NewLauncherDevice
          getDataSource={getDataSource}
          getAgain={getAgain}
          activation={activation}
          buttonLoading={activationLoading}
        />
      ),
    },
  ];

  return (
    <PageComponent routes={["Launcher", "Devices"]}>
      <Tabs items={devicePageTabs} />
    </PageComponent>
  );
}
