import { MenuOutlined, DeleteOutlined, QuestionOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Image, Table, Tooltip, Button, notification, Modal } from "antd";

import React, { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";
import WebSliderDrawer from "./WebSliderDrawer";
import defaultImage from "../../../img/photo.png";
import TableComponent from "../../../_components/table/TableComponent";
import PageComponent from "../../../_components/page/PageComponent";

const Row = ({ children, ...props }) => {
  console.log(props);
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function WebSliderPage() {
  const [dataSource, setDataSource] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const columns = [
    {
      key: "sort",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (record, item, index) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={item.type === "movie" ? item.movie?.poster : item?.channel?.image}
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
              fallback={defaultImage}
            />

            <Tooltip
              placement="topLeft"
              title={item.type === "movie" ? item?.movie?.name : item?.channel?.name}
            >
              <p style={{ margin: 0, marginLeft: 10 }}>
                {item.type === "movie" ? item?.movie?.name : item?.channel?.name}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
      render: (record, item, index) => {
        return item.type === "movie"
          ? item?.movie?.type === "movie"
            ? "Movie"
            : "Tv Show"
          : "Channel";
      },
    },

    {
      title: "Year",
      dataIndex: "year",
      align: "center",
      render: (record, item, index) => {
        return item?.movie?.year;
      },
    },

    {
      title: "",
      dataIndex: "delete",
      align: "center",
      render: (record, item, index) => {
        return (
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => onClickDelete(item)}
          />
        );
      },
    },
  ];

  const onClickDelete = (item) => {
    Modal.confirm({
      title: "Do you want to delete this item",
      icon: <QuestionOutlined />,
      content: "",
      okText: "Delete",
      okButtonProps: {
        style: {
          background: "#d88484",
        },
      },
      onOk() {
        REQUESTS.WEB_PAGE.SLIDER.DELETE({ id: item.id })
          .then(() => {
            getSlider();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err,
            });
          });
      },
    });
  };

  const getSlider = () => {
    setIsLoading(true);
    setIsUpdated(false);

    try {
      const query = {
        pagination: 0,
      };

      REQUESTS.WEB_PAGE.SLIDER.GET({ query: JSON.stringify(query) })
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            return;
          }

          let list = response?.message.map((item) => ({ ...item, key: item.id }));
          if (list?.length > 0) {
            setDataSource(list);
            setIsEmpty(false);
          } else {
            setIsEmpty(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);

        setIsUpdated(true);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    getSlider();
  }, []);

  const updatePositions = () => {
    const list = dataSource.map((item) => item.id);

    const query = {
      position: JSON.stringify(list),
    };

    REQUESTS.WEB_PAGE.SLIDER.EDIT(query)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });
          return;
        }

        getSlider();

        notification.success({
          message: "Success",
          description: response.message,
        });
      })

      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      if (isUpdated) {
        updatePositions();
      }
    }
  }, [dataSource]);

  return (
    <PageComponent routes={["News", "Slider"]}>
      <DndContext onDragEnd={onDragEnd} style={{ zIndex: 0 }}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <TableComponent
            header={
              <>
                <Button type="primary" onClick={() => setIsOpenDrawer(true)}>
                  Add
                </Button>
              </>
            }
            loading={isLoading}
            bordered
            size="small"
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            localeClick={() => setIsOpenDrawer(true)}
            isEmpty={isEmpty}
            locale={() => setIsOpenDrawer(true)}
          />
        </SortableContext>
      </DndContext>

      <WebSliderDrawer
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        getData={getSlider}
      />
    </PageComponent>
  );
}
