import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, Space, notification, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import ButtonComponent from "../../_components/ButtonComponent";
import REQUESTS from "../../api/requests";
import Roles from "./Roles";
import { scrollToTopOfDrawerBody } from "../../config/config";
import ImageUpload from "../../components/ImageUpload";

export default function AdminDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [checkedRoles, setCheckedRoles] = useState([]);

  const [defaultCheckedRoles, setDefaultCheckedRoles] = useState([]);

  const [form] = Form.useForm();

  const [image, setImage] = useState({
    url: null,
    file: null,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const onFinish = (values) => {
    setIsLoading(true);

    let formData = new FormData();

    formData.append("name", values.name);
    formData.append("surname", values.surname);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("roles", checkedRoles.join(","));
    // if (image?.file) {
    //     formData.append("Image", image.file);
    // }

    if (values.password) {
      formData.append("password", values.password);
    }

    try {
      if (editable) {
        formData.append("id", editable.id);

        REQUESTS.ADMINS.EDIT(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            setIsLoading(false);

            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }

            getData();
            onClose();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err,
            });

            setIsLoading(false);
          });
      } else {
        REQUESTS.ADMINS.ADD(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            setIsLoading(false);
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }
            getData();
            onClose();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err,
            });

            setIsLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setCheckedRoles([]);
      setDefaultCheckedRoles([]);
      setIsLoading(false);
    }
    if (editable) {
      console.log(editable, "editable");
      form.setFields([
        { name: "name", value: editable.name },
        { name: "surname", value: editable.surname },
        { name: "email", value: editable.email },
        { name: "phone", value: editable.phone },
      ]);

      const _defaultCheckedRoles = editable.roles.map((item) => item.id);

      setDefaultCheckedRoles(_defaultCheckedRoles);
    }
  }, [open, editable]);

  useEffect(() => {
    if (open) {
      scrollToTopOfDrawerBody();
    }
  }, [open]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} admin`}
      placement="right"
      onClose={onClose}
      open={open}
      width={700}
    >
      <Form
        form={form}
        name="admin"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        autoComplete="false"
      >
        <div
          className="imageContainer"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <Form.Item label="Icon" name="profilePicture">
              <ImageUpload image={image} setImage={setImage} />
            </Form.Item>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              gap: 20,
              height: "170px",
              flexDirection: "column",
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Surname"
              name="surname"
              style={{ width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please input surname",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>

        <div style={{ width: "100%", display: "flex", gap: 20 }}>
          <Form.Item
            label="Email"
            name="email"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            style={{ width: "50%" }}
            rules={[
              {
                required: editable ? false : true,
                message: "Please input password",
              },
            ]}
          >
            <Input.Password autoComplete="false" autoCapitalize="new password" />
          </Form.Item>
        </div>

        <div style={{ width: "100%", display: "flex", gap: 20 }}>
          <Form.Item
            label="Phone"
            name="phone"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input phone",
              },
            ]}
          >
            <InputNumber controls={false} style={{ width: "100%" }} />
          </Form.Item>

          <div style={{ width: "50%" }}></div>
        </div>

        <Roles
          open={open}
          setCheckedRoles={setCheckedRoles}
          defaultCheckedRoles={defaultCheckedRoles}
        />

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
