import { useEffect, useState } from "react";

import { Switch, notification, Table } from "antd";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import REQUESTS from "../../../api/requests";
import PlatformsTable from "./PlatformsTable";
import PageComponent from "../../../_components/page/PageComponent";
import PlatformDrawer from "./PlatformDrawer";

export default function PlatformsPage({}) {
  const [getAgain, setGetAgain] = useState(false);
  const [editable, setEditable] = useState(null);

  const onChange = (checked, item) => {
    const query = {
      id: item.id,
      is_enabled: checked,
    };

    REQUESTS.WEB_PAGE.PLATFORMS.EDIT(query)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err,
        });
      });
  };

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.WEB_PAGE.PLATFORMS.GET(query)
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageComponent routes={["Web page", "Platforms"]}>
      <PlatformsTable
        getDataSource={getDataSource}
        getAgain={getAgain}
        onChange={onChange}
        onEdit={setEditable}
      />

      <PlatformDrawer
        editable={editable}
        open={editable ? true : false}
        onClose={() => setEditable(null)}
        getData={() => setGetAgain((prev) => !prev)}
      />
    </PageComponent>
  );
}
