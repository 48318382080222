import { useEffect, useState } from "react";
import { Flex, Modal, notification, Radio } from "antd";
import { Area } from "@ant-design/plots";

import REQUESTS from "../../../api/requests";
import PageComponent from "../../../_components/page/PageComponent";
import LavieTvTable from "./LavieTvTable";
import LiveTvDrawer from "./drawer/LiveTvDrawer";
import LiveTvVideoModal from "./components/video/LiveTvVideoModal";
import ChartComponent from "../../../_components/chart/ChartComponent";
import { getToken } from "../../../server/requests";

export default function LiveTvPage() {
  const [getAgain, setGetAgain] = useState(false);
  const [playingChannel, setPlayingChannel] = useState(null);
  const [editableChannel, setEditableChannel] = useState(null);
  const [visibleLiveTvDrawer, setVisibleLiveTvDrawer] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [visibleVideoModal, setVisibleVideoModal] = useState(false);

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: "All time",
      query: getDefaultQuery("year"),
      active: false,
    },
    {
      id: 1,
      name: "Year",
      query: getDefaultQuery("month"),
      active: false,
    },
    {
      id: 2,
      name: "Month",
      query: getDefaultQuery("day"),
      active: true,
    },
  ]);

  const [perChannelData, setPerChannelData] = useState(getChartConfig([]));
  const [durationChannelData, setDurationChannelData] = useState(getChartConfig([]));

  function getDefaultQuery(type) {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      type,
      value: -1,
    };
  }

  function getChartConfig(data) {
    return {
      data,
      xField: "date",
      yField: "value",
      label: {},
      point: {
        size: 5,
        shape: "circle",
        style: {
          fill: "white",
          stroke: "#5B8FF9",
          lineWidth: 2,
        },
      },
      tooltip: { showMarkers: false },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: "#000",
            fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
          },
        },
      },
      interactions: [{ type: "marker-active" }],
    };
  }

  const handleApiResponse = (response, successMessage, errorMessage) => {
    if (response.error) {
      notification.error({ message: "Error", description: errorMessage });
    } else {
      notification.success({ message: "Success", description: successMessage });
      setGetAgain((prev) => !prev);
    }
  };

  const getLiveTv = (query, onSuccess, onError) => {
    try {
      REQUESTS.LIVE_TV.CHANNELS.GET(query)
        .then((response) => {
          if (!response.message) {
            notification.error({
              message: "Error",
              description: "Something went wrong",
            });
          } else {
            onSuccess(response.message);
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onEnable = (is_enabled, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("is_enabled", is_enabled);

    REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE(formData)
      .then((response) => {
        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onEnableOrDisableAll = (enable) => {
    REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE_ALL({ enable })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const onChangePosition = (position, id) => {
    REQUESTS.LIVE_TV.CHANNELS.POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: "Do you want to change position",
            content: (
              <div>
                <p>
                  In {position} position already exists <b>{response?.message?.name}</b>{" "}
                  live tv. We can switch their positions. Do you want to do it?
                </p>
              </div>
            ),
            okText: "Change",
            onOk() {
              REQUESTS.LIVE_TV.CHANNELS.POSITION({ position, id, force: true })
                .then(() => {
                  notification.success({
                    message: "Success",
                    description: "Position changed successfully",
                  });
                  setGetAgain((prev) => !prev);
                })
                .catch((err) => {
                  notification.error({
                    message: "Error",
                    description: err.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
        setGetAgain((prev) => !prev);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditableChannel(item);
        setVisibleLiveTvDrawer(true);
        break;
      case "archive":
        handleArchive(item.id);
        break;
      case "restore":
        handleRestore(item.id);
        break;
      case "delete":
        handleDelete(item.id);
        break;
      case "statistics":
        handleStatistics(item.id, item.name);
        break;
      default:
        break;
    }
  };

  const handleArchive = (id) => {
    REQUESTS.LIVE_TV.CHANNELS.ARCHIVE({ id })
      .then((response) =>
        handleApiResponse(response, "Channel successful archived.", response.message)
      )
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleRestore = (id) => {
    REQUESTS.LIVE_TV.CHANNELS.RESTORE({ id })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleDelete = (id) => {
    REQUESTS.LIVE_TV.CHANNELS.DELETE({ id })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleStatistics = (id, name) => {
    setVisibleVideoModal(true);
    setSelectedChannelId(id);
    setChannelName(name);
  };

  const getChannelStatistics = () => {
    const params =
      selectedData.find((item) => item.active)?.query || selectedData[2].query;
    params.value = selectedChannelId;

    const requestsArr = [
      REQUESTS.DASHBOARD.CHARTS.PER_CHANNEL({ query: JSON.stringify(params) }),
      REQUESTS.DASHBOARD.CHARTS.VIEW_CHANNEL_DURATION({ query: JSON.stringify(params) }),
    ];

    Promise.all(requestsArr)
      .then((responses) => {
        if (!responses.some((response) => response.error)) {
          setPerChannelData({ ...perChannelData, data: responses[0]?.message?.data });
          setDurationChannelData({
            ...durationChannelData,
            data: responses[1]?.message?.data,
          });
        }
      })
      .catch((err) => console.log(`err`, err));
  };

  const bulkActions = (type, ids) => {
    const idString = ids.join(",");
    switch (type) {
      case "enable":
        handleBulkEnableDisable(true, idString);
        break;
      case "disable":
        handleBulkEnableDisable(false, idString);
        break;
      case "archive":
        handleBulkArchive(idString);
        break;
      case "restore":
        handleBulkRestore(idString);
        break;
      case "delete":
        handleBulkDelete(idString);
        break;
      default:
        break;
    }
  };

  const handleBulkEnableDisable = (is_enabled, ids) => {
    REQUESTS.LIVE_TV.CHANNELS.ENABLE_DISABLE_BULK({
      id: ids,
      is_enabled,
      token: getToken(),
    })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleBulkArchive = (ids) => {
    REQUESTS.LIVE_TV.CHANNELS.ARCHIVE({ id: ids })
      .then((response) =>
        handleApiResponse(response, "Channels successful archived.", response.message)
      )
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleBulkRestore = (ids) => {
    REQUESTS.LIVE_TV.CHANNELS.RESTORE({ id: ids })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleBulkDelete = (ids) => {
    REQUESTS.LIVE_TV.CHANNELS.DELETE({ id: ids })
      .then((response) => handleApiResponse(response, response.message, response.message))
      .catch((error) => notification.error({ message: "Error", description: error }));
  };

  const handleSelectTab = (value) => {
    const updatedData = selectedData.map((item) => ({
      ...item,
      active: item.id === value.id,
    }));
    setSelectedData(updatedData);
    getChannelStatistics();
  };

  useEffect(() => {
    getChannelStatistics();
  }, [getAgain, selectedChannelId, selectedData]);

  return (
    <PageComponent routes={["Live TV", "Live TV"]}>
      <LavieTvTable
        getAgain={getAgain}
        onEnable={onEnable}
        getDataSource={getLiveTv}
        handleMenuClick={handleMenuClick}
        onChangePosition={onChangePosition}
        openDrawer={() => setVisibleLiveTvDrawer(true)}
        onPlay={(channel) => setPlayingChannel(channel)}
        bulkActions={bulkActions}
        onEnableOrDisableAll={onEnableOrDisableAll}
      />

      <LiveTvDrawer
        open={visibleLiveTvDrawer}
        editable={editableChannel}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setEditableChannel(null);
          setVisibleLiveTvDrawer(false);
        }}
      />

      <LiveTvVideoModal
        channel={playingChannel}
        open={!!playingChannel}
        onClose={() => setPlayingChannel(null)}
      />

      <Modal
        title={channelName}
        open={visibleVideoModal}
        onCancel={() => setVisibleVideoModal(false)}
        footer={null}
        width="70%"
        onOk={() => setVisibleVideoModal(false)}
      >
        <Radio.Group
          value={selectedData.find((item) => item.active)?.id}
          style={{ marginTop: 25 }}
          buttonStyle="solid"
        >
          {selectedData.map((item) => (
            <Radio.Button
              key={item.id}
              value={item.id}
              onClick={() => handleSelectTab(item)}
            >
              {item.name}
            </Radio.Button>
          ))}
        </Radio.Group>

        <Flex gap="10px" justify="center">
          <div style={{ margin: "20px auto", width: "50%" }}>
            <ChartComponent title="Duration Chart">
              <Area {...durationChannelData} />
            </ChartComponent>
          </div>
          <div style={{ margin: "20px auto", width: "50%" }}>
            <ChartComponent title="Per Channel Chart">
              <Area {...perChannelData} />
            </ChartComponent>
          </div>
        </Flex>
      </Modal>
    </PageComponent>
  );
}
