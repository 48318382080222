import { useState } from "react";
import { Upload, Image } from "antd";

import defaultImage from "../img/photo.png";

export default function ImageUpload({ image, setImage, style, label }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setImage({
          url: url,
          file: info.file.originFileObj,
          status: "uploaded",
        });
      });
    }
  };

  const imageError = (e) => (e.target.src = defaultImage);

  return (
    <Upload
      accept="image/*"
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }}
      listType="picture"
      showUploadList={false}
      maxCount={1}
      onChange={onChange}
    >
      {image?.url ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setPreviewVisible(true);
          }}
        >
          <Image
            src={image?.url}
            preview={{
              visible: previewVisible,
              onVisibleChange: setPreviewVisible,
            }}
            alt="avatar"
            style={{
              width: 150,
              height: 160,
              borderRadius: 5,
              border: "1px solid #e5e7e8",
              objectFit: "cover",
              ...style,
              cursor: "pointer",
            }}
            onError={imageError}
          />
          {image?.url && (
            <div
              className="remove-image"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setImage({
                  url: null,
                  file: null,
                  status: "removed",
                });
              }}
            >
              <i className="fa fa-trash" />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            width: 150,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            border: "1px solid #e5e7e8",
            ...style,
          }}
        >
          {label ? label : "Upload"}
        </div>
      )}
      {/* {image?.url && (
        <div
          className="remove-image"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setImage({
              url: null,
              file: null,
              status: "removed",
            });
          }}
        >
          <i className="fa fa-trash" />
        </div>
      )} */}
      {/* {
        <Image
          width={200}
          src={image?.url}
          preview={{
            visible: previewVisible,
            onVisibleChange: setPreviewVisible,
          }}
        />
      } */}
    </Upload>
  );
}
