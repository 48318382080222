import { Button, Image, Modal, notification, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";
import TableButtons from "../../../components/TableButtons";
import NewsDrawer from "./NewsDrawer";
import { QuestionOutlined } from "@ant-design/icons";
import { getColumnSearchProps, getQueryDate, parseDate } from "../../../config/config";
import PageComponent from "../../../_components/page/PageComponent";
import TableComponent from "../../../_components/table/TableComponent";
import icons from "../../../config/icons";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";

export default function NewsPage({}) {
  const [news, setNews] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isEmpty, setIsEmpty] = useState(false);

  const [current, setCurrent] = useState(null);

  const [isOpenNewsDrawer, setIsOpenNewsDrawer] = useState(false);

  const [getAgain, setGetAgain] = useState(false);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [date, setDate] = useState("");

  const [sort, setSort] = useState(["id", "DESC"]);

  const [search, setSearch] = useState({
    question: null,
    answer: null,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        // return limit * (currentPage - 1) + index + 1;
        return index + 1;
      },
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      align: "center",
      ellipsis: {
        showTitle: false,
      },

      render: (record, item, index) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Image
              src={item.image}
              width={30}
              height={30}
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />

            <Tooltip placement="topLeft" title={item.title}>
              <p style={{ margin: 0, marginLeft: 10 }}>{item.title}</p>
            </Tooltip>
          </div>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Description",
      dataIndex: "body",
      key: "body",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (body) => (
        <Tooltip placement="topLeft" title={body}>
          {body}
        </Tooltip>
      ),

      ...getColumnSearchProps(),
    },

    {
      title: `Last updated`,
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: "Edit",
              icon: icons.EDIT,
            },
            {
              key: "delete",
              text: "Delete",
              icon: icons.DELETE,
            },
          ]}
        />
      ),
    },
  ];

  const getNews = () => {
    setIsLoading(true);

    try {
      const query = {
        limit,
        page: currentPage,
        // sort,
      };

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      if (search.body) {
        query.search = {
          ...query.search,
          body: search.body[0],
        };
      }

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.title) {
        query.search = {
          ...query.search,
          title: search.title[0],
        };
      }

      REQUESTS.WEB_PAGE.NEWS.GET({ query: JSON.stringify(query) })
        .then((response) => {
          setIsLoading(false);

          if (response?.message?.rows?.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            return;
          }

          const hasSearchKeyWithValue = () => {
            const keys = Object.keys(search);

            return keys.some((key) => search[key] && search[key][0]);
          };

          if (response?.message?.rows?.length > 0 || hasSearchKeyWithValue()) {
            setNews(response?.message?.rows);
            setIsEmpty(false);
          } else {
            setIsEmpty(true);
          }

          setTotal(response?.message?.total);
          setLimit(response?.message?.limit);
          setCurrentPage(response?.message?.page);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        Modal.confirm({
          title: "Do you want to delete this news",

          icon: <QuestionOutlined />,
          content: "",
          okText: "Delete",
          okButtonProps: {
            style: {
              background: "#d88484",
            },
          },
          onOk() {
            REQUESTS.WEB_PAGE.NEWS.DELETE({ id: item.id })
              .then(() => {
                getNews();
              })
              .catch((err) => {
                notification.error({
                  message: "Error",
                  description: err,
                });
              });
          },
        });
        break;
      case "edit":
        setCurrent(item);
        setIsOpenNewsDrawer(true);
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const closeDrawer = () => {
    setCurrent(null);
    setIsOpenNewsDrawer(false);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getNews();
      console.log("get news");
    }, [500]);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, currentPage, date, getAgain]);

  return (
    <PageComponent routes={["Web page", "News"]}>
      <TableComponent
        header={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            {/* <h3>NEWS</h3> */}
            <Button type="primary" onClick={() => setIsOpenNewsDrawer(true)}>
              Add News
            </Button>
          </div>
        }
        rowKey="id"
        size="small"
        bordered
        columns={columns}
        dataSource={news}
        onChange={handleTableChange}
        loading={isLoading}
        localeClick={() => setIsOpenNewsDrawer(true)}
        isEmpty={isEmpty}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
      />

      <NewsDrawer
        open={isOpenNewsDrawer}
        onClose={closeDrawer}
        getData={getNews}
        current={current}
        getAgain={() => setGetAgain((prev) => !prev)}
      />
    </PageComponent>
  );
}
