import { Empty, notification } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import Server from "../../../_components/server/Server";
import Loading from "../../../_components/loadings/Loading";
import PageComponent from "../../../_components/page/PageComponent";

import styles from "./_transcoders-servers.module.scss";

export default function TranscodersServersPage({}) {
  const [servers, setServers] = useState([]);
  const [getAgain, setGetAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getServers = (haveLoading) => {
    if (haveLoading) setIsLoading(true);

    try {
      REQUESTS.TRANSCODERS.SERVERS.GET()
        .then((response) => {
          if (haveLoading) setIsLoading(false);

          if (response?.error) {
            return;
          }
          setServers(response?.message);
        })
        .catch(() => {
          if (haveLoading) setIsLoading(true);
        });
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: "Something went wrong",
      });
      console.log(e);
    }
  };

  const deleteServer = (item) => {
    try {
      REQUESTS.TRANSCODERS.SERVERS.DELETE({ id: item.id })
        .then((response) => {
          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message,
            });

            return;
          }

          notification.success({
            message: "Success",
            description: "Server is deleted",
          });

          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getServers(true);
  }, [getAgain]);

  useEffect(() => {
    const interval = setInterval(() => {
      getServers(false);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <PageComponent routes={["Streamer", "Servers"]}>
      {isLoading ? (
        <Loading />
      ) : servers.length === 0 ? (
        <div
          style={{
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Empty description="You do not have servers." />
        </div>
      ) : (
        <div className={styles["servers-list"]}>
          {servers.map((item) => {
            return (
              <Server
                item={item}
                onDelete={() => deleteServer(item)}
                type="gpu"
                key={item?.id}
              />
            );
          })}
        </div>
      )}
    </PageComponent>
  );
}
