import { notification } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import CatchUpRecordingsTable from "./CatchUpRecordingsTable";
import PageComponent from "../../../_components/page/PageComponent";

export default function CatchUpRecordingsPage() {
  const [getAgain, setGetAgain] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.CATCH_UP.RECORDINGS.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "delete":
        REQUESTS.CATCH_UP.RECORDINGS.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }

            notification.success({
              message: "Success",
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

      default:
        break;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setGetAgain((prev) => !prev);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [getAgain]);

  return (
    <PageComponent routes={["Catch-up", "Recordings"]}>
      <CatchUpRecordingsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
      />
    </PageComponent>
  );
}
