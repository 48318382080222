import { useState } from "react";
import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import VodGenresTable from "./VodGenresTable";

import VodGenresDrawer from "./VodGenresDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function VodGenresPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.VOD.GENRES.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.VOD.GENRES.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      case "protect":
      case "unprotect":
        const body = {
          id: item.id,
          is_protected: event.key === "protect" ? true : false,
        };

        REQUESTS.VOD.GENRES.PROTECT(body)
          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }
            notification.success({
              message: "Success",
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      default:
        break;
    }
  };

  const handleBulkDelete = (ids) => {
    REQUESTS.VOD.GENRES.DELETE_BULK({ ids })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: "Success",
            description: response.message,
          });
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  return (
    <PageComponent routes={["Vod", "Genres"]}>
      <VodGenresTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        handleBulkDelete={handleBulkDelete}
      />

      <VodGenresDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
